// src/components/LocationInput.js
import React, { useRef } from 'react';
import { Autocomplete } from '@react-google-maps/api';

const LocationInput = ({ value, onChange, placeholder }) => {
  const autocompleteRef = useRef(null);

  const handleSelect = () => {
    const place = autocompleteRef.current?.getPlace();
    if (place) {
      onChange(place.formatted_address || ""); // Use the formatted address if available
    } else {
      alert("Please enter the address manually.");
    }
  };

  return (
    <Autocomplete
      onLoad={(ref) => (autocompleteRef.current = ref)}
      onPlaceChanged={handleSelect}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={{ width: "100%", padding: "10px" }}
        required
      />
    </Autocomplete>
  );
};

export default LocationInput;
