// src/components/UserSelect.js
import React from 'react';

const UserSelect = ({ users, selectedUser, onSelectUser }) => (
  <select
    value={selectedUser}
    onChange={(e) => onSelectUser(e.target.value)}
  >
    <option value="">Select User</option>
    {users.map((user) => (
      <option key={user.id} value={user.username}>{user.username}</option>
    ))}
  </select>
);

export default UserSelect;
