import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './DateMonthYearPicker.css';

const DateMonthYearPicker = ({ currentDate, onDateChange }) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };

  const handleDateSelection = (date) => {
    onDateChange(date);
    setIsDatePickerOpen(false); // Close the date picker after date selection
  };

  const handleTodayClick = () => {
    handleDateSelection(new Date()); // Reuse handleDateSelection
  };

  const handleClearClick = () => {
    handleDateSelection(new Date().setDate(1)); // Reuse handleDateSelection for setting to start of the month
  };

  return (
    <div className="header-dmp">
      <button onClick={toggleDatePicker}  className='CalButtons'>
        {moment(currentDate).format('MMMM YYYY')}
      </button>
      {isDatePickerOpen && (
        <div className="date-picker-container">
          <DatePicker
            selected={currentDate}
            onChange={handleDateSelection} // Updated to use handleDateSelection
            inline
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
           
          />
          <div className="picker-buttons">
            <button onClick={handleTodayClick} className="today-button">Today</button>
            <button onClick={handleClearClick} className="clear-button">Clear</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateMonthYearPicker;
