import React, { useState } from 'react';
import './Table.css';

const Table = ({ columns, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  // Total pages calculated from data length and records per page
  const totalPages = Math.ceil(data.length / recordsPerPage);
  
  // Calculate the slice of data to display
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);

  // Handlers for pagination
  const goToPage = (pageNumber) => {
    setCurrentPage(Math.max(1, Math.min(pageNumber, totalPages))); // Clamp between 1 and totalPages
  };

  // Render page size selector
  const renderPageSizeSelector = () => {
    const options = [5, 10, 20, 'All'];
    return (
      <select
        value={recordsPerPage}
        onChange={(e) => {
          setRecordsPerPage(e.target.value === 'All' ? data.length : Number(e.target.value));
          setCurrentPage(1); // Reset to first page when changing record size
        }}
      >
        {options.map((option) => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    );
  };

  // Render page controls
  const renderPageControls = () => {
    return (
      <div className="pagination">
        <button onClick={() => goToPage(1)} disabled={currentPage === 1}>
          First
        </button>
        <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>
          Prev
        </button>
        <input
          type="number"
          value={currentPage}
          min={1}
          max={totalPages}
          onChange={(e) => goToPage(Number(e.target.value))}
        />
        <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
        <button onClick={() => goToPage(totalPages)} disabled={currentPage === totalPages}>
          Last
        </button>
      </div>
    );
  };

  return (
    <div className="table-container">
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{row[column.field]}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                {renderPageSizeSelector()}
                {renderPageControls()}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Table;
