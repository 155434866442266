// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.floatingLabelInput {
    position: relative;
    /* border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1em; */
    padding-top: 10px; 
  }
  
  .floatingLabelInput input {
    width: 100%;
    padding: 8px 10px;
    font-size: 16px;
    border: none;
    outline: none;
    box-sizing: border-box;
  }
  
  .floatingLabelInput .label {
    position: absolute;
    left: 10px;
    top: 70%;
    transform: translateY(-50%);
    color: #999;
    font-weight: normal;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .floatingLabelInput input:focus + .label,
  .floatingLabelInput input:not(:placeholder-shown) + .label {
    top: -25px;
    transform: translateY(100%);
    color: #000;
    font-weight: bold;
  }
  
  /* Additional style to highlight the input border on focus */
  .floatingLabelInput input:focus {
    border-color: #66afe9;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TextInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB;;yBAEqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;EACtB;;EAEA;;IAEE,UAAU;IACV,2BAA2B;IAC3B,WAAW;IACX,iBAAiB;EACnB;;EAEA,4DAA4D;EAC5D;IACE,qBAAqB;EACvB","sourcesContent":[".floatingLabelInput {\n    position: relative;\n    /* border: 1px solid #ccc;\n    border-radius: 4px;\n    margin-bottom: 1em; */\n    padding-top: 10px; \n  }\n  \n  .floatingLabelInput input {\n    width: 100%;\n    padding: 8px 10px;\n    font-size: 16px;\n    border: none;\n    outline: none;\n    box-sizing: border-box;\n  }\n  \n  .floatingLabelInput .label {\n    position: absolute;\n    left: 10px;\n    top: 70%;\n    transform: translateY(-50%);\n    color: #999;\n    font-weight: normal;\n    transition: all 0.3s ease;\n    pointer-events: none;\n  }\n  \n  .floatingLabelInput input:focus + .label,\n  .floatingLabelInput input:not(:placeholder-shown) + .label {\n    top: -25px;\n    transform: translateY(100%);\n    color: #000;\n    font-weight: bold;\n  }\n  \n  /* Additional style to highlight the input border on focus */\n  .floatingLabelInput input:focus {\n    border-color: #66afe9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
