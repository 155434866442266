// src/LoginPage.js

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import logo from '../../assets/aew&dlogo.png';
import './loginpage.css';

const NavigateRegisterButton = () => {
  const navigate = useNavigate();
  return <button className="LogInPageButtons" onClick={() => navigate('/register')}>Register</button>;
};

const LoginPage = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate(); 

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api-aecalendarapp.aeglazing.com/api/login/', {
        username: username,
        password: password,
      });
  
      localStorage.setItem('authToken', response.data.token); // Save the token
  
      // Make an additional request to get the user info
      const userInfoResponse = await axios.get('https://api-aecalendarapp.aeglazing.com/api/user-info/', {
        headers: { 'Authorization': `Token ${response.data.token}` }
      });
  
      localStorage.setItem('userId', userInfoResponse.data.user_id); // Save the user ID
      localStorage.setItem('userGroups', JSON.stringify(userInfoResponse.data.groups));
      localStorage.setItem('username', username);

      onLoginSuccess(response.data.token); // Call the onLoginSuccess function from App.js
      navigate('/'); // Redirect to the home page after successful login
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setErrorMessage(`Error: ${error.response.data.detail || 'Invalid credentials'}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        setErrorMessage('No response from server.');
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
        setErrorMessage('Error logging in.');
      }
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="AE Logo" className="logo-login" />
      <h2>Login</h2>
      {errorMessage && <p>{errorMessage}</p>}
      <form onSubmit={handleLogin}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
         <div className='password-field-container'>
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button onClick={togglePasswordVisibility} type="button" className="password-eye-icon">
            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </button>
        </div>
        <button type="submit" className="LogInPageButtons">Log In</button>
        <NavigateRegisterButton />
      </form>
    </div>
  );
};

export default LoginPage;
