import React, { useState, useEffect, useCallback } from "react";
import NavBar from "../../components/NavBar/NavBar";
import Table from "../../components/Table";
import Modal from "../../components/Modal/Modal"; // Adjusted import path for Modal
import DateInput from "../../components/DateInput";
import {
  fetchEventsByCurrentWeek,
  fetchEventsByCurrentMonth,
  fetchEventsByCurrentYear,
  fetchEventsByDateRange,
} from "../../app/apiService"; // Adjust the path to your actual ApiService
import "./JobStatus.css";

const jobStatusNavItems = [
  { id: "Install", label: "Install" },
  { id: "Survey", label: "Survey" },
  { id: "Completed", label: "Completed" },
];

const columns = [
  { headerName: "ID", field: "id" },
  { headerName: "Title", field: "title" },
  { headerName: "Description", field: "description" },
  { headerName: "Start Date", field: "start", type: "datetime" },
  { headerName: "End Date", field: "end", type: "datetime" },
];

const JobStatus = () => {
  const [activeTab, setActiveTab] = useState(jobStatusNavItems[0].id);
  const [events, setEvents] = useState([]);
  const [counts, setCounts] = useState({
    Install: 0,
    Survey: 0,
    Completed: 0,
  });
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [customDateRange, setCustomDateRange] = useState({
    start: "",
    end: "",
  }); // State to hold custom date range
  const [activeFilter, setActiveFilter] = useState("week");
  const [isCustomDateRangeActive, setIsCustomDateRangeActive] = useState(false);

  const fetchData = useCallback(
    async (fetchFunction) => {
      try {
        const data = await fetchFunction(activeTab);
        setEvents(data);
        // You might want to update counts here if required
      } catch (error) {
        console.error("Error fetching events: ", error);
      }
    },
    [activeTab]
  );

  useEffect(() => {
    // Fetch data for the current week by default on component mount
    fetchData(fetchEventsByCurrentWeek);
  }, [fetchData]);

  const handleFilterClick = (filterType) => {
    setActiveFilter(filterType);
    setIsCustomDateRangeActive(false);
    switch (filterType) {
      case "week":
        fetchData(fetchEventsByCurrentWeek);
        break;
      case "month":
        fetchData(fetchEventsByCurrentMonth);
        break;
      case "year":
        fetchData(fetchEventsByCurrentYear);
        break;
      case "custom":
        setShowModal(true); // Show modal for custom date range
        break;
      default:
        break;
    }
  };

  const fetchAndUpdateCounts = useCallback(
    async (startDate, endDate) => {
      const newCounts = { Install: 0, Survey: 0, Completed: 0 };

      // Determine the fetch function based on the filter or use the custom date range function
      const getFetchFunction = (filter) => {
        const fetchFunctions = {
          week: fetchEventsByCurrentWeek,
          month: fetchEventsByCurrentMonth,
          year: fetchEventsByCurrentYear,
          custom: (eventType) =>
            fetchEventsByDateRange(startDate, endDate, eventType),
        };
        return fetchFunctions[filter];
      };

      try {
        // Fetch counts for all event types (Install, Survey, Completed)
        for (const tabItem of jobStatusNavItems) {
          const fetchFunc = getFetchFunction(
            isCustomDateRangeActive ? "custom" : activeFilter
          );
          const eventsData = await fetchFunc(tabItem.id);
          newCounts[tabItem.id] = eventsData.length;
        }
      } catch (error) {
        console.error("Error fetching event counts: ", error);
      }

      setCounts(newCounts);
    },
    [activeFilter, isCustomDateRangeActive]
  );

  useEffect(() => {
    fetchAndUpdateCounts();
  }, [activeTab, activeFilter, fetchAndUpdateCounts]);

  const handleDateRangeSubmit = () => {
    if (customDateRange.start && customDateRange.end) {
      fetchEventsByDateRange(
        customDateRange.start,
        customDateRange.end,
        activeTab
      )
        .then((data) => {
          setEvents(data);
          setShowModal(false); // Close modal after fetch
          setIsCustomDateRangeActive(true); // Set the custom date range as active
          // Call to update counts for custom date range here
          fetchAndUpdateCounts(customDateRange.start, customDateRange.end);
        })
        .catch((error) =>
          console.error("Error fetching events with custom date range: ", error)
        );
    }
  };

  const renderTabContent = () => {
    return <Table columns={columns} data={events} />;
  };

  return (
    <>
      <NavBar
        navItems={jobStatusNavItems.map((item) => ({
          ...item,
          label: `${item.label} (${counts[item.id]})`,
        }))}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showSearch={true}
      />
      <div className="filter-buttons">
        <button
          onClick={() => handleFilterClick("week")}
          className={`nav-item ${
            activeFilter === "week" ? "button-active" : ""
          }`}
        >
          This Week
        </button>
        <button
          onClick={() => handleFilterClick("month")}
          className={`nav-item ${
            activeFilter === "month" ? "button-active" : ""
          }`}
        >
          This Month
        </button>
        <button
          onClick={() => handleFilterClick("year")}
          className={`nav-item ${
            activeFilter === "year" ? "button-active" : ""
          }`}
        >
          This Year
        </button>
        <button
          onClick={() => handleFilterClick("custom")}
          className={`nav-item ${
            activeFilter === "custom" ? "button-active" : ""
          }`}
        >
          Custom Range
        </button>
      </div>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <h2>Select Date Range</h2>
        <DateInput
          value={customDateRange.start}
          onChange={(newDate) =>
            setCustomDateRange((prev) => ({ ...prev, start: newDate }))
          }
          format="YYYY-MM-DD"
        />
        <DateInput
          value={customDateRange.end}
          onChange={(newDate) =>
            setCustomDateRange((prev) => ({ ...prev, end: newDate }))
          }
          format="YYYY-MM-DD"
        />
        <button onClick={handleDateRangeSubmit}>Submit</button>
      </Modal>
      <div className="tab-content">{renderTabContent()}</div>
    </>
  );
};

export default JobStatus;
