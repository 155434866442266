// src/components/EventTypeButton.js
import React, { useState } from 'react';

const EventTypeButton = ({ eventType, currentEventType, setEventType, children, activeColor, hoverColor }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isActive = eventType === currentEventType;

  // Determine button style based on active/hover state
  const buttonStyle = {
    backgroundColor: isActive ? activeColor : "initial",
    borderColor: isActive || isHovered ? activeColor : "black",
    color: isActive || isHovered ? '#ffffff' : '#000000', // Optional: change text color on active/hover
    borderWidth: "1px",
    borderStyle: "solid",
  };

  return (
    <button
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={isActive || isHovered ? { ...buttonStyle, backgroundColor: hoverColor } : buttonStyle}
      onClick={() => setEventType(eventType)}
    >
      {children}
    </button>
  );
};

export default EventTypeButton;


