// src/EventDetailsModal.js

import React, { useState } from "react";
import Modal from 'react-modal';
import './eventdetailsmodal.css';

Modal.setAppElement('#root'); 

const customStyles = {
  content: {
    position: 'absolute', // Use absolute positioning
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)', // This will center the modal
    width: '19rem', // Fixed width
    maxHeight: '80vh', // Max height to ensure it doesn't overflow the viewport
    overflowY: 'auto', // Scroll inside modal if content is too large
    border: '1px solid #ccc',
    borderRadius: '0.5rem',
    padding: '1rem',
    background: '#c0dce5',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    zIndex: 1000 // Ensure it's above other content
  },
};


const ImageModal = ({ isOpen, onRequestClose, imageUrl }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <img src={imageUrl} alt="Event" style={{ width: '100%' }} />
  </Modal>
);

const EventDetailsModal = ({ event, isOpen, onRequestClose, onEdit, onDelete, userGroups }) => {

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  const openImageModal = (imageUrl) => {
    setCurrentImageUrl(imageUrl);
    setIsImageModalOpen(true);
  };
  const isEngineer = userGroups.includes('Engineer');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Event Details"
      style={customStyles}
    >
      <h2>{event.title}</h2>
      <p>Description: {event.description}</p>
      <p>Start: {event.start.toLocaleString()}</p>
      <p>End: {event.end.toLocaleString()}</p>
      <p>Type of Event: {event.event_type}</p>
      <p>Material Arrival Date: {event.material_arrival_date}</p>
      <p>Customer Name: {event.customer_name}</p>
      <p>Customer Email: {event.customer_email}</p>
      <p>Mobile Number: {event.mobile_number}</p>
      <p>Address: {event.address}</p>
      <p>Event User: {event.event_user}</p>
      {!isEngineer && (
        <>
      <p>Total Job Cost : £{event.total_job_cost}</p>
      <p>Material Cost: £{event.material_cost}</p>
      <p>Labour Cost: £{event.labour_cost}</p>
      <p>Job Profit: £{event.job_profit}</p>
      </>
      )}
      <p>External Link: <a href={event.external_link} target="_blank" rel="noopener noreferrer">{event.external_link}</a></p>
      {event.event_image_url && (
        <div onClick={() => openImageModal(event.event_image_url)}>
          <img src={event.event_image_url} alt="Event Thumbnail" style={{ width: '100px', cursor: 'pointer' }} />
        </div>
      )}
      <ImageModal
        isOpen={isImageModalOpen}
        onRequestClose={() => setIsImageModalOpen(false)}
        imageUrl={currentImageUrl}
      />
      {!isEngineer && (
        <>
          <button className='edit-button' onClick={() => onEdit(event)}>Edit</button>
          <button className='delete-button' onClick={() => onDelete(event)}>Delete</button>
        </>
      )}
      <button className='close-button' onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default EventDetailsModal;
