// src/App.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import { LoadScript } from "@react-google-maps/api";
import LoginPage from '../features/authentication/LoginPage';
import RegistrationPage from '../features/authentication/RegistrationPage';
import MyCalendar from '../pages/calendar/MyCalendar';
import Sidebar from './../components/Sidebar/Sidebar';
import Dashboard from '../pages/dashboard/Dashboard';
import JobStatus from '../pages/jobStatus/JobStatus';
import FullPageCard from '../components/FullPageCard';
import './App.css';

const BASE_URL = 'https://api-aecalendarapp.aeglazing.com/api';

const googleMapsLibraries = ["places"];

function App() {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [events, setEvents] = useState([]);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const fetchEvents = useCallback(async () => {
    if (authToken) {
      try {
        const response = await axios.get(`${BASE_URL}/events/`, {
          headers: {
            Authorization: `Token ${authToken}`
          }
        });
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    }
  }, [authToken]);  

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);  

  const handleLoginSuccess = (token) => {
    localStorage.setItem('authToken', token);
    setAuthToken(token);
    fetchEvents();
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={googleMapsLibraries}>
      <Router>
        <div className="App">
       
        {authToken && <Sidebar collapsed={isSidebarCollapsed} setCollapsed={setIsSidebarCollapsed} setAuthToken={setAuthToken} />}
          <div className={`content ${authToken ? (isSidebarCollapsed ? 'content-collapsed' : 'content-expanded') : ''}`}>
            <Routes>
              <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
              <Route path="/register" element={<RegistrationPage />} />
              <Route 
                path="/" 
                element={authToken ? <MyCalendar setAuthToken={setAuthToken} events={events} fetchEvents={fetchEvents} /> : <Navigate to="/login" />}
              />
               <Route path="/dashboard" element={
              authToken ? (
                <>
                  <header className="header">Dashboard Page</header>
                  <FullPageCard>
                    <Dashboard />
                  </FullPageCard>
                  <footer className="footer">Footer Content</footer>
                </>
              ) : <Navigate to="/login" />
            } />
            <Route path="/job-status" element={
              authToken ? (
                <>
                  <header className="header">Job Status Page</header>
                  <FullPageCard>
                    <JobStatus />
                  </FullPageCard>
                  <footer className="footer">Footer Content</footer>
                </>
              ) : <Navigate to="/login" />
            } />
            </Routes>
          </div>
        </div>
      </Router>
    </LoadScript>
  );
}

export default App;