// src/RegistrationPage.js

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import logo from '../../assets/aew&dlogo.png';
import "./registrationpage.css";

const NavigateLoginButton = () => {
  const navigate = useNavigate();
  return (
    <button className="LogInPageButtons" onClick={() => navigate("/login")}>
      Login
    </button>
  );
};

const RegistrationPage = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [usernameValidationMessage, setUsernameValidationMessage] = useState("");
  const [emailValidationMessage, setEmailValidationMessage] = useState("");
  const [passwordValidationMessage, setPasswordValidationMessage] = useState("");

  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const validatePassword = (password) => {
    const lengthCondition = password.length >= 8 && password.length <= 20;
    const specialCharCondition = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const uppercaseCondition = /[A-Z]/.test(password);
    const numberCondition = /[0-9]/.test(password);

    // Set the state for each condition
    setIsPasswordLongEnough(lengthCondition);
    setHasSpecialChar(specialCharCondition);
    setHasUppercase(uppercaseCondition);
    setHasNumber(numberCondition);
  };

  // Handle password change
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Reset validation messages
    setUsernameValidationMessage("");
    setEmailValidationMessage("");
    setPasswordValidationMessage("");

    // Check for username
    if (!username) {
      console.log("Username is required.");
      setUsernameValidationMessage("Username is required.");
      return;
    }
    // Do the same for email and password.

    // Check for email
    if (!email) {
      setEmailValidationMessage("Email is required.");
      return; // stop the function if validation fails
    }
    // Check for password
    if (!password) {
      setPasswordValidationMessage("Password is required.");
      return; // stop the function if validation fails
    }

    try {
      const response = await axios.post('https://api-aecalendarapp.aeglazing.com/api/register/', {
        username: username,
        password: password,
        email: email,
      });
      if (response.status === 201 || response.status === 200) { // Assuming 201 Created or 200 OK is a successful registration
        navigate('/login'); // Redirect to the login page
      } else {
        // Handle any other HTTP status codes as you see fit
        setErrorMessage('Registration unsuccessful.');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to register");
      console.error("Registration error", error);
    }
  };

  return (
    <div className="register-container">
      <img src={logo} alt="AE Logo" className="logo-register" />
      <h2>Register</h2>
      {errorMessage && <p>{errorMessage}</p>}
      <form onSubmit={handleRegister}>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
        {usernameValidationMessage && (
          <p className="validation-message">{usernameValidationMessage}</p>
        )}

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        {emailValidationMessage && (
          <p className="validation-message">{emailValidationMessage}</p>
        )}

        <div className="password-field-container">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            placeholder="Password"
            required
          />
          <button
            onClick={togglePasswordVisibility}
            type="button"
            className="password-eye-icon"
          >
            {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
          </button>
        </div>
        {passwordValidationMessage && (
          <p className="validation-message">{passwordValidationMessage}</p>
        )}

        {isPasswordFocused && (
          <div>
            <p style={{ color: isPasswordLongEnough ? "green" : "red" }}>
              8-20 characters
            </p>
            <p style={{ color: hasSpecialChar ? "green" : "red" }}>
              1 special character
            </p>
            <p style={{ color: hasUppercase ? "green" : "red" }}>
              1 uppercase letter
            </p>
            <p style={{ color: hasNumber ? "green" : "red" }}>1 number</p>
          </div>
        )}
        <button
          className="LogInPageButtons"
          type="submit"
          disabled={
            !isPasswordLongEnough ||
            !hasSpecialChar ||
            !hasUppercase ||
            !hasNumber
          }
        >
          Register
        </button>
        <NavigateLoginButton />
      </form>
    </div>
  );
};

export default RegistrationPage;
