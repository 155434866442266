import React from 'react';

const DateTimeInput = ({ value, onChange, placeholder }) => {
  // Use the value prop to control the value of the input and call the onChange prop to update the parent component's state
  return (
    <input
      type="datetime-local"
      placeholder={placeholder}
      value={value || ''} // Controlled component: use value from props
      onChange={onChange} // Pass the event up to the parent component
    />
  );
};

export default DateTimeInput;
