// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
    color: #020202;
    text-align: left;
  }

  .delete-button {
    background-color: #d14035;
    color: white;
  }
  
  .edit-button {
    background-color: #a09f9e;
    color: white;
  }
  
  .close-button {
    background-color: #0c3274;
    color: white;
  }`, "",{"version":3,"sources":["webpack://./src/pages/calendar/eventdetailsmodal.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":["p {\n    color: #020202;\n    text-align: left;\n  }\n\n  .delete-button {\n    background-color: #d14035;\n    color: white;\n  }\n  \n  .edit-button {\n    background-color: #a09f9e;\n    color: white;\n  }\n  \n  .close-button {\n    background-color: #0c3274;\n    color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
