// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* // src/components/Modal/Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  position: relative;
}

.modal-close-button {
  position: absolute;
  right: 10px;
  cursor: pointer;
  bottom: 22px;
}

.modal-submit-button {
    background-color: #28a745; /* Bootstrap's .btn-success background color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px; /* Spacing above the button */
    outline: none;
  }
  
  .modal-submit-button:hover {
    background-color: #218838; /* Darken the button on hover */
  }
  
  .modal-submit-button:active {
    background-color: #1e7e34; /* Even darker on click */
  }`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,YAAY;AACd;;AAEA;IACI,yBAAyB,EAAE,8CAA8C;IACzE,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB,EAAE,6BAA6B;IAC/C,aAAa;EACf;;EAEA;IACE,yBAAyB,EAAE,+BAA+B;EAC5D;;EAEA;IACE,yBAAyB,EAAE,yBAAyB;EACtD","sourcesContent":["/* // src/components/Modal/Modal.css */\n\n.modal-overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n}\n\n.modal-content {\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 4px;\n  position: relative;\n}\n\n.modal-close-button {\n  position: absolute;\n  right: 10px;\n  cursor: pointer;\n  bottom: 22px;\n}\n\n.modal-submit-button {\n    background-color: #28a745; /* Bootstrap's .btn-success background color */\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-top: 10px; /* Spacing above the button */\n    outline: none;\n  }\n  \n  .modal-submit-button:hover {\n    background-color: #218838; /* Darken the button on hover */\n  }\n  \n  .modal-submit-button:active {\n    background-color: #1e7e34; /* Even darker on click */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
