// src/components/Charts/CurrentMonthOrdersChart.js

import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { fetchEventsByCurrentMonth } from '../../app/apiService';  // Adjust the path if necessary
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const eventTypes = ['Install', 'Survey', 'Completed'];

const CurrentMonthOrdersChart = () => {
  const [currentMonthEvents, setCurrentMonthEvents] = useState({
    Install: 0,
    Survey: 0,
    Completed: 0
  });

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsPromises = eventTypes.map(eventType =>
          fetchEventsByCurrentMonth(eventType).catch(e => {
            console.error(`Error fetching events for type ${eventType}:`, e);
            return []; // Return an empty array in case of error
          })
        );
  
        const eventsResults = await Promise.all(eventsPromises);
        console.log('eventsResults', eventsResults); // Log the raw results
  
        const updatedEvents = eventTypes.reduce((acc, eventType, index) => {
          acc[eventType] = eventsResults[index].length;
          console.log(`Event Type: ${eventType}, Count: ${eventsResults[index].length}`);
          return acc;
        }, {});
  
        console.log('updatedEvents', updatedEvents); // Log the processed counts
        setCurrentMonthEvents(updatedEvents);
      } catch (error) {
        console.error('Error fetching all events:', error);
      }
    };
  
    fetchEvents();
  }, []);
  
  

  const chartData  = {
    labels: eventTypes,
    datasets: [
      {
        label: 'Current Month Order Status',
        data: [currentMonthEvents.Install, currentMonthEvents.Survey, currentMonthEvents.Completed],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(153, 102, 255, 0.2)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(153, 102, 255, 1)'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    layout: {
        padding: {
          top: 50, // Add this line
        },
      },
    scales: {
      x: {
        type: 'category',
        ticks: {
          font: {
            size: 14, // Example size
            weight: 'bold', // Bolder font
          }
        }
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
          }
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 14,
            weight: 'bold',
          }
        }
      },
      title: {
        display: false,
        text: 'Order Status by Type',
        font: {
          size: 18,
          weight: 'bold',
        }
      },
      datalabels: {
        color: '#444',
        anchor: 'end',
        align: 'top',
        font: {
            size: 15,
          weight: 'bold',
        },
        formatter: (value, context) => {
          // Only display the label if the value is greater than 0
          return value > 0 ? value : '';
        },
      }
    },
    // ... other options
  };

  return (
    <div style={{ height: '400px' }}>
        <h2 style={{ textAlign: 'center' }}>Order Status by Type</h2> {/* Add this line */}
      <Bar data={chartData} options={options} style={{ height: '300px' }}/>
      
    </div>
  );
};


export default CurrentMonthOrdersChart;
