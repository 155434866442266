// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    background-color: #b1aeae1f;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    margin: 10px; /* This gives space between the cards */
    padding: 20px; /* Inner spacing of the card */
    transition: transform 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: translateY(-5px); /* Optional: slight raise effect on hover */
  }`, "",{"version":3,"sources":["webpack://./src/components/Card/Card.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,wCAAwC;IACxC,YAAY,EAAE,uCAAuC;IACrD,aAAa,EAAE,8BAA8B;IAC7C,sCAAsC;EACxC;;EAEA;IACE,2BAA2B,EAAE,2CAA2C;EAC1E","sourcesContent":[".card {\n    background-color: #b1aeae1f;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);\n    margin: 10px; /* This gives space between the cards */\n    padding: 20px; /* Inner spacing of the card */\n    transition: transform 0.3s ease-in-out;\n  }\n  \n  .card:hover {\n    transform: translateY(-5px); /* Optional: slight raise effect on hover */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
