// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-page-card {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.62);
  overflow: hidden; /* this hides overflow, ensure there's enough room for content */
  background-color: #ffffff;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/FullPageCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2CAA2C;EAC3C,gBAAgB,EAAE,gEAAgE;EAClF,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".full-page-card {\n  display: flex;\n  flex-direction: column;\n  border-radius: 15px;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.62);\n  overflow: hidden; /* this hides overflow, ensure there's enough room for content */\n  background-color: #ffffff;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
