// src\pages\dashboard\Dashboard.js
import React, { useState, useEffect } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import FullPageCard from '../../components/FullPageCard';
import DateInput from '../../components/DateInput';
import Modal from '../../components/Modal/Modal';
import Card from '../../components/Card/Card';
import CurrentWeekOrdersChart from '../../components/Charts/CurrentWeekOrdersChart';
import CurrentMonthOrdersChart from '../../components/Charts/CurrentMonthOrdersChart';
import CurrentYearOrdersChart from '../../components/Charts/CurrentYearOrdersChart';
import CustomRangeOrdersChart from '../../components/Charts/CustomRangeOrdersChart';
import CurrentWeekIncomeChart from '../../components/Charts/CurrentWeekIncomeChart';
import CurrentMonthIncomeChart from '../../components/Charts/CurrentMonthIncomeChart';
import CurrentYearIncomeChart from '../../components/Charts/CurrentYearIncomeChart';
import CustomRangeIncomeChart from '../../components/Charts/CustomRangeIncomeChart';
import { fetchEventsByCurrentWeek, fetchEventsByCurrentMonth, fetchEventsByCurrentYear, fetchEventsByDateRange, fetchIncomeByCurrentWeek, fetchIncomeByCurrentMonth, fetchIncomeByCurrentYear, fetchIncomeByDateRange } from '../../app/apiService';

import './Dashboard.css';

const dashboardNavItems = [
  { id: 'week', label: 'This Week' },
  { id: 'month', label: 'This Month' },
  { id: 'year', label: 'This Year' },
  { id: 'custom', label: 'Custom range' }
];

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(dashboardNavItems[0].id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [eventChartData, setEventChartData] = useState(null);
  const [incomeChartData, setIncomeChartData] = useState(null);

  const handleStartDateChange = (newDate) => setStartDate(newDate);
  const handleEndDateChange = (newDate) => setEndDate(newDate);

  const handleNavClick = (id) => {
    setActiveTab(id);
    setIsModalOpen(id === 'custom');
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (activeTab === 'week') {
          const eventResults = await fetchEventsByCurrentWeek();
          const incomeResults = await fetchIncomeByCurrentWeek();
          setEventChartData(eventResults);
          setIncomeChartData(incomeResults);
        } else if (activeTab === 'month') {
          const eventResults = await fetchEventsByCurrentMonth();
          const incomeResults = await fetchIncomeByCurrentMonth();
          setEventChartData(eventResults);
          setIncomeChartData(incomeResults);
        } else if (activeTab === 'year') {
          const eventResults = await fetchEventsByCurrentYear();
          const incomeResults = await fetchIncomeByCurrentYear();
          setEventChartData(eventResults);
          setIncomeChartData(incomeResults);
        }
      } catch (error) {
        console.error(`Failed to fetch data for the current ${activeTab}: `, error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab]);

  const handleDateRangeSubmit = async () => {
    const start = new Date(startDate);
    const end = new Date(endDate);
  
    if (start > end) {
      alert("End date cannot be before the start date.");
      return;
    }
    
    setIsModalOpen(false);
    setLoading(true);
  
    try {
      const eventResults = await fetchEventsByDateRange(startDate, endDate);
      const incomeResults = await fetchIncomeByDateRange(startDate, endDate);
      setEventChartData(eventResults);
      setIncomeChartData(incomeResults);
    } catch ( error) {
      console.error("Failed to fetch data for custom range:", error);
    } finally {
      setLoading(false);
    }
  };

  const renderEventContent = () => {
    if (loading) return <div>Loading...</div>;
    switch (activeTab) {
      case 'week':
        return <CurrentWeekOrdersChart data={eventChartData} />;
      case 'month':
        return <CurrentMonthOrdersChart data={eventChartData} />;
      case 'year':
        return <CurrentYearOrdersChart data={eventChartData} />;
      case 'custom':
        return <CustomRangeOrdersChart data={eventChartData} startDate={startDate} endDate={endDate} />;
      default:
        return null;
    }
  };

  const renderIncomeContent = () => {
    if (loading) return <div>Loading...</div>;
    switch (activeTab) {
      case 'week':
        return <CurrentWeekIncomeChart data={incomeChartData} />;
      case 'month':
        return <CurrentMonthIncomeChart data={incomeChartData} />;
      case 'year':
        return <CurrentYearIncomeChart data={incomeChartData} />;
      case 'custom':
        return <CustomRangeIncomeChart data={incomeChartData} startDate={startDate} endDate={endDate} />;
      default:
        return null;
    }
  };

  return (
    <>
      <NavBar 
        navItems={dashboardNavItems} 
        activeTab={activeTab} 
        setActiveTab={handleNavClick}
        showSearch={false}
      />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="custom-date-range">
          <DateInput value={startDate} onChange={handleStartDateChange} format="YYYY-MM-DD" />
          <DateInput value={endDate} onChange={handleEndDateChange} format="YYYY-MM-DD" />
          <button className="modal-submit-button" onClick={handleDateRangeSubmit}>
            Submit
          </button>
        </div>
      </Modal>
      <FullPageCard>
        <div className="cards-container">
          <Card>
            {renderEventContent()}
          </Card>
          <Card>
            {renderIncomeContent()}
          </Card>
        </div>
      </FullPageCard>
    </>
  );
};

export default Dashboard;
