// src/apiService.js
import axios from 'axios';

const BASE_URL =  'https://api-aecalendarapp.aeglazing.com/api'; 

// Create an instance of axios with the API base URL
const apiInstance = axios.create({
  baseURL: BASE_URL,
});

// Function to handle API errors
const handleError = (error) => {
  console.error('API call failed. Error:', error);
  throw error;
};

// GET - Fetch events
export const fetchEvents = () => {
  return apiInstance.get('/events/')
    .then(response => response.data)
    .catch(handleError);
};

// GET - Fetch users
export const fetchUsers = () => {
  return apiInstance.get('/users/') 
    .then(response => response.data)
    .catch(handleError);
};

// POST - Create a new event
export const createEvent = async (eventData, token) => {
  try {
    const response = await axios.post(`${BASE_URL}/events/`, eventData, {
      headers: {
        Authorization: `Token ${token}`,
        // Do NOT set Content-Type here; let Axios handle it
      }
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Helper functions to validate and prepare fields
const prepareNumericValue = (value) => value ? parseFloat(value) : 0;
const isValidURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false; // Not a valid URL
  }
};

// Updated function to prepare data and make PUT request
export const updateEvent = async (eventData, token) => {
  const formData = new FormData();

  // Extract and prepare data correctly before appending
  let eventDataId = eventData.get('id');
  formData.append('title', eventData.get('title'));
  formData.append('start', eventData.get('start'));
  formData.append('end', eventData.get('end'));
  formData.append('event_type', eventData.get('event_type'));
  formData.append('customer_name', eventData.get('customer_name'));
  formData.append('customer_email', eventData.get('customer_email'));
  formData.append('material_cost', prepareNumericValue(eventData.get('material_cost')));
  formData.append('labour_cost', prepareNumericValue(eventData.get('labour_cost')));
  formData.append('total_job_cost', prepareNumericValue(eventData.get('total_job_cost')));
  formData.append('job_profit', prepareNumericValue(eventData.get('job_profit')));

  // Check for a valid external link
  const externalLink = eventData.get('external_link');
  if (externalLink && isValidURL(externalLink)) {
    formData.append('external_link', externalLink);
  } else {
    formData.append('external_link', ''); // Send an empty string or handle as needed
  }

  formData.append('mobile_number', eventData.get('mobile_number'));
  formData.append('address', eventData.get('address'));
  formData.append('event_user', eventData.get('event_user'));
  formData.append('user', eventData.get('user'));

  // Append event_image if available and is a file
  const eventImage = eventData.get('event_image');
  if (eventImage && typeof eventImage !== "string") {
    formData.append('event_image', eventImage);
  }

  try {
    const response = await axios({
      method: 'put',
      url: `${BASE_URL}/events/${eventDataId}/`,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update event:', error.response ? error.response.data : error);
    throw error;
  }
};


export const updateEventDragDrop = async (eventData, token) => {
  const formData = new FormData();
  let eventDataId = eventData.id; // Get the event ID directly

  // Prepare and validate data before appending
  const prepareNumericValue = (value) => value ? parseFloat(value) : 0;
  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false; // Not a valid URL
    }
  };

  formData.append('title', eventData.title);
  formData.append('start', eventData.start.toISOString());
  formData.append('end', eventData.end.toISOString());
  formData.append('event_type', eventData.event_type);
  formData.append('customer_name', eventData.customer_name);
  formData.append('customer_email', eventData.customer_email);
  formData.append('material_cost', prepareNumericValue(eventData.material_cost));
  formData.append('labour_cost', prepareNumericValue(eventData.labour_cost));
  formData.append('total_job_cost', prepareNumericValue(eventData.total_job_cost));
  formData.append('job_profit', prepareNumericValue(eventData.job_profit));

  // Check for a valid external link
  const externalLink = eventData.external_link;
  if (externalLink && isValidURL(externalLink)) {
    formData.append('external_link', externalLink);
  } else {
    formData.append('external_link', ''); // Send an empty string or handle as needed
  }

  formData.append('mobile_number', eventData.mobile_number);
  formData.append('address', eventData.address);
  formData.append('event_user', eventData.event_user);
  formData.append('user', eventData.user);

  // Only append event_image if it's not a string (indicating it's a file and not a URL or other string)
  const eventImage = eventData.event_image;
  if (eventImage && typeof eventImage !== "string") {
    formData.append('event_image', eventImage);
  }

  try {
    const response = await axios({
      method: 'put',
      url: `${BASE_URL}/events/${eventDataId}/`,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update event:', error.response ? error.response.data : error);
    throw error;
  }
};





// DELETE - Delete an event
export const deleteEvent = async (eventId, token) => {
  try {
    await axios.delete(`${BASE_URL}/events/${eventId}/`, {
      headers: {
        Authorization: `Token ${token}`
      }
    });
  } catch (error) {
    throw error;
  }
};

//  For Job status page -  START
    
//      get events from date for status - START

export const fetchEventsByDate = (date, type) => {
  return apiInstance.get(`/events-by-date/?date=${date}&type=${encodeURIComponent(type)}`)  
    .then(response => response.data)
    .catch(handleError);
};


//      get events from date for status - END

// For Job status page -  END

// # *****************************************************************************************************************
// # ************************************* PAGE CHANGE ***************************************************
// # *****************************************************************************************************************

// # For dashboard page -  START

//     # Get events by current week - START

export const fetchEventsByCurrentWeek = (eventType) => {
  return axios.get(`${BASE_URL}/events-by-current-week/?type=${encodeURIComponent(eventType)}`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

//     # Get events by current week - END

// # Get events by current month - START

export const fetchEventsByCurrentMonth = (eventType) => {
  return axios.get(`${BASE_URL}/events-by-current-month/?type=${encodeURIComponent(eventType)}`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

// # Get events by current month - END

// # Get events by current year - START

export const fetchEventsByCurrentYear = (eventType) => {
  return axios.get(`${BASE_URL}/events-by-current-year/?type=${encodeURIComponent(eventType)}`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

// # Get events by current year - END

// # Get events by custom date range year - START

export const fetchEventsByDateRange = (startDate, endDate, eventType) => {
  return axios.get(`${BASE_URL}/events-by-date-range/?start=${startDate}&end=${endDate}&type=${encodeURIComponent(eventType)}`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

// # Get events by custom date range year - END

// # Get income  by current week - START

export const fetchIncomeByCurrentWeek = () => {
  return axios.get(`${BASE_URL}/income-by-current-week/`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

// # Get events by current week - END

// # Get income  by current month - START

export const fetchIncomeByCurrentMonth = () => {
  return axios.get(`${BASE_URL}/income-by-current-month/`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

// # Get events by current month - END

// # Get income  by current year - START

export const fetchIncomeByCurrentYear = () => {
  return axios.get(`${BASE_URL}/income-by-current-year/`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

// # Get events by current year - END

// # Get income  by current custom range - START

export const fetchIncomeByDateRange = (startDate, endDate) => {
  return axios.get(`${BASE_URL}/income-by-date-range/?start=${startDate}&end=${endDate}`)
    .then(response => response.data)
    .catch(error => {
      console.error('API call failed: ', error);
      throw error;
    });
};

// # Get events by current custom range - END

// # For dashboard page -  END