// src/components/NavBar/NavBar.js
import React, { useState } from 'react';
import './NavBar.css';

const NavBar = ({ navItems, setActiveTab, activeTab, children, showSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Implement search logic here if needed
  };

  return (
    <nav className="navbar">
      <div className="nav-items-container">
        {navItems.map((item, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(item.id)}
            className={`nav-item ${activeTab === item.id ? 'active' : ''}`}
          >
            {item.label}
          </button>
        ))}
      </div>
      {children} 
      {showSearch && (
      <div className="navbar-search">
        <input
          type="text"
          className="search-input"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      )}
    </nav>
  );
};

NavBar.defaultProps = {
  showSearch: true
};

export default NavBar;
