// src/components/ModalButtons.js
import React from 'react';

const ModalButtons = ({ isEditMode, onSave, onClear, onClose, canSave }) => (
  <div className="modal-buttons">
    {canSave && (
      <button className="save-button" onClick={onSave}>
        {isEditMode ? "Update" : "Save"}
      </button>
    )}
    <button className="clear-button" onClick={onClear}>
      {isEditMode ? "Clear Fields" : "Clear"}
    </button>
    <button className="close-button" onClick={onClose}>
      Close
    </button>
  </div>
);

export default ModalButtons;
