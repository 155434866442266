// Sidebar.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate here
import './Sidebar.css';

const Sidebar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate(); // Hook to navigate to different routes
  const location = useLocation();
  const [activeItem, setActiveItem] = useState('/'); // Default active item is the home route

  useEffect(() => {
    // Set the active item based on the current pathname
    const path = location.pathname === '/' ? 'calendar' : location.pathname.substring(1);
    setActiveItem(path);
  }, [location]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  // Function to handle item click
  const handleItemClick = (itemName, path) => {
    setActiveItem(itemName); // Set the active item
    navigate(path); // Navigate to the path
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : 'expanded'}`}>
      <button onClick={toggleSidebar} className="toggle-button">
        {collapsed ? '→' : '←'}
      </button>
      <div 
        className={`menu-item ${activeItem === 'calendar' ? 'active' : ''}`} 
        onClick={() => handleItemClick('calendar', '/')}
      >
        <div className="emoji">📅</div>
        {!collapsed && <div className="label">Calendar</div>}
      </div>
      <div 
        className={`menu-item ${activeItem === 'dashboard' ? 'active' : ''}`} 
        onClick={() => handleItemClick('dashboard', '/dashboard')}
      >
        <div className="emoji">📊</div>
        {!collapsed && <div className="label">Dashboard</div>}
      </div>
      <div 
        className={`menu-item ${activeItem === 'job-status' ? 'active' : ''}`} 
        onClick={() => handleItemClick('job-status', '/job-status')}
        >
        <div className="emoji">🏠</div>
        {!collapsed && <div className="label">Job Status</div>}
      </div>
    </div>
  );
};

export default Sidebar;
