// src/MyCalendar.js changes

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import EventDetailsModal from './EventDetailsModal';
import DateMonthYearPicker from './DateMonthYearPicker';
import CreateEventModal from './CreateEventModal';
import { createEvent, updateEvent, updateEventDragDrop  } from '../../app/apiService';
import { deleteEvent } from '../../app/apiService';
import './MyCalendar.css';
import logo from '../../assets/aew&dlogo.png';

const BASE_URL =  'https://api-aecalendarapp.aeglazing.com/api';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const NavigateRegisterButton = ({ setAuthToken }) => {
  const navigate = useNavigate();

  const handleRegister = () => {
    localStorage.removeItem('authToken'); // Clear the authToken from storage
    setAuthToken(null);                   // Update state to reflect logged out status
    navigate('/register');                // Navigate to the register page
  };

  return <button className="CalButtons" onClick={handleRegister}>Register</button>;
};

const MyCalendar = ({
  setAuthToken, // Function to update auth token state in App
}) => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setModalIsOpen] = useState(false);
  // const [newEventSlot, setNewEventSlot] = useState(null);

  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState('');
  const [newEventStart, setNewEventStart] = useState('');
  const [newEventEnd, setNewEventEnd] = useState('');
  const [newEventDescription, setNewEventDescription] = useState('');
  const [newEventType, setNewEventType] = useState('');
  const [newMaterialArrivalDate, setNewMaterialArrivalDate] = useState('');
  const [newCustomerName, setNewCustomerName] = useState('');
  const [newCustomerEmail, setnewCustomerEmail] = useState('');
  const [newMaterialCost, setnewMaterialCost] = useState('');
  const [newLabourCost, setnewLabourCost] = useState('');
  const [newTotalJobCost, setnewTotalJobCost] = useState('');
  const [newJobProfit, setnewJobProfit] = useState('');
  const [newExternalLink, setnewExternalLink] = useState('');
  const [newMobileNumber, setNewMobileNumber] = useState('');  
  const [editAddress, setEditAddress] = useState('');
  const [calendarKey, setCalendarKey] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [currentUsername, setCurrentUsername] = useState('');

  const eventStyleGetter = (event, allUsers) => {
    let backgroundColor = stringToColor(event.event_user); 
    let borderColor = '#FFFFFF'; // White border by default
    let textColor = getContrastYIQ(backgroundColor); 
    if (event.event_type === 'Survey') {
      borderColor = '#FF5722'; // Orange border for Survey
    } else if (event.event_type === 'Install') {
      borderColor = '#3174ad'; // Blue border for Install
    }
    else if (event.event_type === 'Completed') {
      borderColor = '#1f7632'; // Green border for Completed
    }
    const style = {
      backgroundColor,
      borderColor,
      borderRadius: '15px',
      opacity: 0.8,
      color: textColor,
      border: `4px solid ${borderColor}`,
      display: 'block',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)', 
      fontSize: '0.9em',
      padding: '0px 12px',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      fontWeight: '500',
    };
  
    return {
      style
    };
  };
  

function stringToColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

function getContrastYIQ(hexcolor){
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0,2),16);
  var g = parseInt(hexcolor.substr(2,2),16);
  var b = parseInt(hexcolor.substr(4,2),16);
  var yiq = ((r*299)+(g*587)+(b*114))/1000;
  return (yiq >= 128) ? 'black' : 'white';
}

  // Function to fetch users from the backend
  const fetchUsers = useCallback(async () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      try {
        const response = await axios.get(`${BASE_URL}/users/`, { 
          headers: {
            Authorization: `Token ${token}`
          }
        });
        setAllUsers(response.data); 
        const loggedInUserId = parseInt(localStorage.getItem('userId'));
        const loggedInUser = response.data.find(user => user.id === loggedInUserId);
        if (loggedInUser) {
          setCurrentUsername(loggedInUser.username);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    }
  }, []);

// Function to fetch events from the backend
const fetchEvents = useCallback(async () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    try {
      const response = await axios.get(`${BASE_URL}/events/`, {
        headers: {
          Authorization: `Token ${token}`
        }
      });

      let formattedEvents = response.data.map(event => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        allDay: event.all_day,
        event_user: event.event_user
      }));

      // Filter events if the user is an Engineer
      const isEngineer = userGroups.includes('Engineer');
  if (isEngineer) {
    const username = localStorage.getItem('username');
    formattedEvents = formattedEvents.filter(event => event.event_user === username);
  }
      
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }
}, [userGroups]);


  

useEffect(() => {
  const groups = JSON.parse(localStorage.getItem('userGroups')) || [];
  setUserGroups(groups);
  fetchUsers(); 
}, [fetchUsers]); 

useEffect(() => {
  fetchEvents(); 
}, [fetchEvents]); 

  

   // Function to handle the selection of slots on the calendar
   const handleSelectSlot = (slotInfo) => {
    const isEngineer = userGroups.includes('Engineer');
    if (!isEngineer) {
    setShowCreateEventModal(true);
    setIsEditMode(false);
    setSelectedEvent(null); 
    setNewEventStart(slotInfo.start);
    setNewEventEnd(slotInfo.end);
  } else {
  }
  };
  


   // Function to handle saving the event to both the local state and backend
 // This function should be called when the user clicks the 'Save' button on the form
 const handleSaveEvent = async (eventData) => {
  const start = new Date(eventData.start);
  const end = new Date(eventData.end);

  const token = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');

  if (!userId) {
    console.error("Logged-in user ID not found");
    return;
  }

  if (!token) {
    console.error("User token not found");
    return;
  }

  // if (!eventData.title || !eventData.start || !eventData.end) {
  //   alert("22222222222222 Please fill in all fields.");
  //   return;
  // }
  
  // if (start >= end) {
  //   alert('The start time must be before the end time.');
  //   return;
  // }

  for (let [key, value] of eventData.entries()) {
    console.log(`${key}: ${value}`); // This will log each key and value
  }

  try {
    // const formData = new FormData();
    // formData.append('title', eventData.title.trim());
    // formData.append('description', eventData.description.trim());
    // formData.append('start', new Date(eventData.start).toISOString());
    // formData.append('end', new Date(eventData.end).toISOString());
    // formData.append('all_day', eventData.allDay);
    // formData.append('event_type', eventData.event_type);
    // formData.append('material_arrival_date', eventData.material_arrival_date);
    // formData.append('customer_name', eventData.customer_name);
    // formData.append('customer_email', eventData.customer_email);
    // formData.append('material_cost', eventData.material_cost);
    // formData.append('labour_cost', eventData.labour_cost);
    // formData.append('total_job_cost', eventData.total_job_cost);
    // formData.append('job_profit', eventData.job_profit);
    // formData.append('external_link', eventData.external_link);
    // formData.append('mobile_number', eventData.mobile_number);
    // formData.append('address', eventData.address);
    // formData.append('event_user', eventData.event_user);
    // formData.append('user', userId);

    // if (eventData.eventImage) {
    //   formData.append('event_image', eventData.eventImage);
    // }

    let savedEvent;
    if (isEditMode) {
      savedEvent = await updateEvent(eventData, token);
    } else {
      savedEvent = await createEvent(eventData, token);
    }

    setEvents(prevEvents => [...prevEvents, savedEvent]);
    await fetchEvents(); // Fetch events again to refresh the calendar
    setShowCreateEventModal(false);
    if (isEditMode) {
      setIsEditMode(false); // Reset edit mode
    }
  } catch (error) {
    console.error("Failed to save event:", error);
  }
};

// chnages 
const formatDateForInput = (dateStr) => {
  const date = new Date(dateStr); // Parse string into Date object if necessary
  return date.toISOString().slice(0, 16);
};


const handleEditEvent = (event) => {
  const formattedStart = formatDateForInput(event.start);
  const formattedEnd = formatDateForInput(event.end);

  setSelectedEvent(event); // Set the selected event to the one to be edited
  setIsEditMode(true); // Enable edit mode
  setModalIsOpen(false); // Close the details modal
  setShowCreateEventModal(true); // Open the create/edit event modal
  // Set the form fields to the event's current values
  setNewEventTitle(event.title);
  setNewEventStart(formattedStart);
  setNewEventEnd(formattedEnd);
  setNewEventDescription(event.description);
  setNewEventType(event.eventType);
  setNewMaterialArrivalDate(event.materialArrivalDate);
  setNewCustomerName(event.customerName);
  setnewCustomerEmail(event.customerEmail);
  setnewMaterialCost(event.materialCost);
    setnewLabourCost(event.labourCost);
    setnewTotalJobCost(event.totalJobCost);
    setnewJobProfit(event.jobProfit);
    setnewExternalLink(event.externalLink);
  setNewMobileNumber(event.mobileNumber);
  setEditAddress(event.address);
  setSelectedUser(event.event_user);
};
  
  const handleEventSelect = (event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const moveEvent = async ({ event, start, end }) => {
    const idx = events.findIndex(e => e.id === event.id);
    const updatedEvent = { ...event, start: new Date(start), end: new Date(end) };

  
    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        console.error("User token not found");
        return;
      }
  
      const response = await updateEventDragDrop(updatedEvent, token);
      response.start = new Date(response.start);
      response.end = new Date(response.end);
      const updatedEvents = [...events].map(evt => {
        if (evt.id === event.id) {
          return { ...evt, start: new Date(start), end: new Date(end) };
        }
        return { ...evt };
      });
      updatedEvents.splice(idx, 1, response); // Replace the old event with the updated one
      setEvents(updatedEvents);
      setCalendarKey(prevKey => prevKey + 1);
      console.log("Events after update:", updatedEvents);
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };
  

  const handleDateChange = (date) => {
    setCurrentDate(date);
    
    setEvents(prevEvents => prevEvents.map(event => ({
      ...event,
      start: new Date(date), 
      end: new Date(date).setHours(new Date(date).getHours() + 1), 
    })));
  };

  const onNavigate = (newDate) => {
    setCurrentDate(newDate);
  };
  
  const handleDeleteEvent = async (event) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this event?");
    if (confirmDelete) {
      try {
        const token = localStorage.getItem('authToken'); // Assuming you're using token-based auth
        await deleteEvent(event.id, token);
        await fetchEvents(); // Refresh events after deletion
        setModalIsOpen(false); // Close the modal
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };  
  
  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Clear token from storage
    setAuthToken(null);                   // Clear authToken state to trigger re-renders
    navigate('/login');                   // Redirect to login
};

// return 
return (
    <DndProvider backend={HTML5Backend}>
      <div className="calendar-container">
      <div className="header-container">
        <div className="logo-welcome-container">
          <img src={logo} alt="AE Logo" className="logo" />
          <span className="welcome-message">Welcome {currentUsername}</span>
        </div>
      <DateMonthYearPicker
          currentDate={currentDate}
          onDateChange={handleDateChange}
        />
        <div className="buttons-container">
        <NavigateRegisterButton setAuthToken={setAuthToken} />
        <button className="CalButtons" onClick={handleLogout}>Logout</button>
        </div>
       
        </div>
        <DnDCalendar
          key={calendarKey}
          localizer={localizer}
          events={events}
          onEventDrop={moveEvent}
          resizable
          onEventResize={moveEvent}
          defaultDate={currentDate}
          defaultView="month"
          views={['month', 'day', 'work_week', 'week', 'agenda']}
          date={currentDate}
          onNavigate={onNavigate}
          onSelectEvent={handleEventSelect}
          selectable={true}
          onSelectSlot={handleSelectSlot}
          style={{ height: '80vh', margin: '20px' }}
          className="calendar"
          eventPropGetter={eventStyleGetter}
        />
         {selectedEvent && (
          <EventDetailsModal
            event={selectedEvent}
            isOpen={isModalOpen} 
            onRequestClose={() => {
              setSelectedEvent(null);
              setModalIsOpen(false); 
            }}
            onEdit={handleEditEvent}
            onDelete={handleDeleteEvent}
            userGroups={userGroups}
          />
        )}
       {showCreateEventModal && (
          <CreateEventModal
            userGroups={userGroups}
            isOpen={showCreateEventModal && !userGroups.includes('Engineer')}
            onClose={() => setShowCreateEventModal(false)}
            onSave={handleSaveEvent}
            isEditMode={isEditMode} 
            existingEvent={selectedEvent} 
            // Pass the title, start, and end times to the modal
            eventTitle={newEventTitle}
            onTitleChange={setNewEventTitle}
            startDateTime={newEventStart}
            onStartDateTimeChange={setNewEventStart}
            endDateTime={newEventEnd}
            onEndDateTimeChange={setNewEventEnd}
            eventDescription={newEventDescription}
            onDescriptionChange={setNewEventDescription}
            eventEventType={newEventType}
            onEventTypeChange={setNewEventType}
            materialArrivalDate={newMaterialArrivalDate}
            onMaterialArrivalDateChange={setNewMaterialArrivalDate}
            customerName={newCustomerName} 
            customerEmail={newCustomerEmail}
            materialCost={newMaterialCost}
            labourCost={newLabourCost}
            totalJobCost={newTotalJobCost}
            jobProfit={newJobProfit}
            externalLink={newExternalLink}
            onCustomerNameChange={setNewCustomerName}
            onCustomerEmailChange={setnewCustomerEmail}
            onMaterialCostChange={setnewMaterialCost}
            onLabourCostChange={setnewLabourCost}
            onTotalJobCostChange={setnewTotalJobCost}
            onJobProfitChange={setnewJobProfit}
            onExternalLinkChange={setnewExternalLink}
            mobileNumber={newMobileNumber}
            onMobileNumberChange={setNewMobileNumber}
            address={editAddress}
            onAddressChange={setEditAddress}
            allUsers={allUsers}
            eventUser={selectedUser}
            onEventUserChange={setSelectedUser}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default MyCalendar;
