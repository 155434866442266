// src\components\FullPageCard.js

import React from 'react';
import './FullPageCard.css';

const FullPageCard = ({ children }) => {
  return <div className="full-page-card">{children}</div>;
};

export default FullPageCard;
