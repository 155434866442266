import React from 'react';

const DateInput = ({ value, onChange, placeholder }) => {
  return (
    <input
      type="date"
      placeholder={placeholder}
      value={value} // Controlled component: use value from props
      onChange={(e) => onChange(e.target.value)} // Pass the change event up to the parent component
    />
  );
};

export default DateInput;
