import React, { useState } from 'react';
import './TextInput.css'; // make sure to create this CSS file

const TextInput = ({ label, value, onChange, placeholder, type = 'text' }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="floatingLabelInput">
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <label className={(value || isFocused) ? 'label active' : 'label'}>
        {label}
      </label>
    </div>
  );
};

export default TextInput;
