// src/CreateEventModal.js

import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import "./CreateEventModal.css";

import TextInput from "../../components/TextInput";
import DateTimeInput from "../../components/DateTimeInput";
import EventTypeButton from "../../components/EventTypeButton";
import UserSelect from "../../components/UserSelect";
import LocationInput from "../../components/LocationInput";
import ModalButtons from "../../components/ModalButtons";
import DateInput from "../../components/DateInput";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "19rem",
    maxHeight: "80vh",
    overflowY: "auto",
    border: "1px solid #ccc",
    borderRadius: "0.5rem",
    padding: "1rem",
    background: "#c7deea",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
  },
};

const formatDateForInput = (dateObj) => {
  if (!dateObj) return "";
  const date = new Date(dateObj);
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}T${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
};

const CreateEventModal = ({
  isOpen,
  onClose,
  onSave,
  isEditMode,
  existingEvent,
  userGroups,
  eventEventType = "",
  onEventTypeChange,
  materialArrivalDate = "",
  onMaterialArrivalDateChange,
  customerName = "",
  onCustomerNameChange,
  customerEmail = "",
  onCustomerEmailChange,
  materialCost = "",
  onMaterialCostChange,
  labourCost = "",
  onLabourCostChange,
  totalJobCost = "",
  onTotalJobCostChange,
  jobProfit = "",
  onJobProfitChange,
  externalLink = "",
  onExternalLinkChange,

  mobileNumber = "",
  onMobileNumberChange,
  address,
  onAddressChange,
  eventUser,
  onEventUserChange,
  allUsers,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [eventImage, setEventImage] = useState(null);
  const isEngineer = userGroups.includes("Engineer");

  const resetState = useCallback(() => {
    // Wrap resetState with useCallback
    setTitle("");
    setDescription("");
    setStartDateTime("");
    setEndDateTime("");
    onEventTypeChange("");
    onMaterialArrivalDateChange("");
    onCustomerNameChange("");
    onCustomerEmailChange("");
    onMaterialCostChange("");
    onJobProfitChange("");
    onLabourCostChange("");
    onExternalLinkChange("");
    onTotalJobCostChange("");
    onMobileNumberChange("");
    onAddressChange("");
    onEventUserChange("");
    setEventImage(null);
  }, [
    onEventTypeChange,
    onMaterialArrivalDateChange,
    onCustomerNameChange,
    onCustomerEmailChange,
    onMaterialCostChange,
    onJobProfitChange,
    onLabourCostChange,
    onExternalLinkChange,
    onTotalJobCostChange,
    onMobileNumberChange,
    onAddressChange,
    onEventUserChange,
  ]);

  // Set the initial values when the modal is opened for editing
  useEffect(() => {
    if (isEditMode && existingEvent) {
      setTitle(existingEvent.title);
      setDescription(existingEvent.description);
      const formattedStart = formatDateForInput(existingEvent.start);
      const formattedEnd = formatDateForInput(existingEvent.end);
      setStartDateTime(formattedStart);
      setEndDateTime(formattedEnd);
      onEventTypeChange(existingEvent.event_type);
      onMaterialArrivalDateChange(existingEvent.material_arrival_date);
      onCustomerNameChange(existingEvent.customer_name);
      onCustomerEmailChange(existingEvent.customer_email);
      onMaterialCostChange(existingEvent.material_cost);
      onJobProfitChange(existingEvent.job_profit);
      onLabourCostChange(existingEvent.labour_cost);
      onExternalLinkChange(existingEvent.external_link);
      onTotalJobCostChange(existingEvent.total_job_cost);
      onMobileNumberChange(existingEvent.mobile_number);
      onAddressChange(existingEvent.address);
      onEventUserChange(existingEvent.event_user);
      setEventImage(existingEvent.event_image || null);
    } else {
      resetState();
    }
  }, [
    isEditMode,
    existingEvent,
    onEventTypeChange,
    onMaterialArrivalDateChange,
    onCustomerNameChange,
    onCustomerEmailChange,
    onMaterialCostChange,
    onJobProfitChange,
    onLabourCostChange,
    onExternalLinkChange,
    onTotalJobCostChange,
    onMobileNumberChange,
    onAddressChange,
    onEventUserChange,
    resetState,
  ]);

  const handleSave = () => {
    // Basic field validation
    if (
      !title.trim() ||
      !startDateTime ||
      !endDateTime ||
      !eventUser ||
      !address.trim()
    ) {
      alert("Please fill in all required fields.");
      return;
    }

    // Check date validity
    if (new Date(startDateTime) >= new Date(endDateTime)) {
      alert("The start time must be before the end time.");
      return;
    }

    // Check if external link is valid only in update mode
    if (isEditMode && externalLink && !isValidURL(externalLink)) {
      alert("Enter a valid URL.");
      return;
    }

    // Construct the formData object with necessary conversions
    const formData = new FormData();
    formData.append("title", title.trim());
    formData.append("description", description.trim());
    formData.append("start", new Date(startDateTime).toISOString());
    formData.append("end", new Date(endDateTime).toISOString());
    formData.append("allDay", false);
    formData.append("event_type", eventEventType);
    formData.append("customer_name", customerName.trim());
    formData.append("customer_email", customerEmail.trim());
    formData.append(
      "material_cost",
      materialCost ? parseFloat(materialCost) : 0
    );
    formData.append("labour_cost", labourCost ? parseFloat(labourCost) : 0);
    formData.append(
      "total_job_cost",
      totalJobCost ? parseFloat(totalJobCost) : 0
    );
    formData.append("job_profit", jobProfit ? parseFloat(jobProfit) : 0);

    // Append external link if it's a valid URL, otherwise append an empty string
    if (externalLink && isValidURL(externalLink)) {
      formData.append("external_link", externalLink.trim());
    } else if (externalLink) {
      alert("Enter a valid URL.");
      return;
    }

    formData.append("mobile_number", mobileNumber.trim());
    formData.append("address", address.trim());
    formData.append("event_user", eventUser);

    // Format material_arrival_date to YYYY-MM-DD
    if (materialArrivalDate) {
      const formattedDate = new Date(materialArrivalDate)
        .toISOString()
        .slice(0, 10);
      formData.append("material_arrival_date", formattedDate);
    } else {
      formData.append("material_arrival_date", ""); // or do not append if not necessary
    }

    if (localStorage.getItem("userId")) {
      formData.append("user", localStorage.getItem("userId"));
    }

    if (eventImage) {
      formData.append("event_image", eventImage);
    }

    if (isEditMode && existingEvent) {
      formData.append("id", existingEvent.id);
    }

    onSave(formData);
    onClose();
    if (!isEditMode) {
      resetState();
    }
  };

  function isValidURL(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false; // Not a valid URL
    }
  }

  useEffect(() => {
    const total = parseFloat(totalJobCost) || 0;
    const material = parseFloat(materialCost) || 0;
    const labour = parseFloat(labourCost) || 0;
    const profit = total - (material + labour);
    onJobProfitChange(profit.toString());
  }, [totalJobCost, materialCost, labourCost, onJobProfitChange]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      style={customStyles}
    >
      <h2 className="modal-header">
        {isEditMode ? "Update Event" : "Create New Event"}
      </h2>
      <TextInput
        label="Event Title"
        placeholder=" "
        value={title}
        onChange={setTitle}
      />
      <TextInput
        label="Description"
        placeholder=" "
        value={description}
        onChange={setDescription}
      />
      <label style={{ fontWeight: "bold", color: "#0c3274" }}>
        Event Start Date and Time
      </label>
      <DateTimeInput
        placeholder="Start Date and Time"
        value={startDateTime}
        onChange={(e) => setStartDateTime(e.target.value)} // Make sure to pass the value, not the event
      />

      <DateTimeInput
        placeholder="End Date and Time"
        value={endDateTime}
        onChange={(e) => setEndDateTime(e.target.value)} // Make sure to pass the value, not the event
      />
      <div>
        <label style={{ fontWeight: "bold", color: "#0c3274" }}>
          Select Event Type:
        </label>
        <EventTypeButton
          eventType="Survey"
          currentEventType={eventEventType}
          setEventType={onEventTypeChange}
          children="Survey"
          activeColor="#ff8159" // Orange for active Survey button
          hoverColor="#ffab59" // Lighter orange for hover
        />
        <EventTypeButton
          eventType="Install"
          currentEventType={eventEventType}
          setEventType={onEventTypeChange}
          children="Install"
          activeColor="#589cc1" // Blue for active Install button
          hoverColor="#85b4d0" // Lighter blue for hover
        />
        <EventTypeButton
          eventType="Completed"
          currentEventType={eventEventType}
          setEventType={onEventTypeChange}
          children="Completed"
          activeColor="#37af51" // Green for active Install button
          hoverColor="#37af51a3" // Lighter green for hover
        />
      </div>

      <TextInput
        label="Customer Name"
        placeholder=" "
        value={customerName}
        onChange={onCustomerNameChange}
      />
      <TextInput
        label="Customer Email"
        placeholder=" "
        value={customerEmail}
        onChange={onCustomerEmailChange}
      />
      <TextInput
        label="Mobile Number"
        placeholder=" "
        value={mobileNumber}
        onChange={onMobileNumberChange}
      />
      <label style={{ fontWeight: "bold", color: "#0c3274" }}>
        Material Arrival Date
      </label>
      <DateInput
        placeholder="Material Arrival Date"
        value={materialArrivalDate}
        onChange={onMaterialArrivalDateChange}
      />
      <LocationInput
        placeholder="Add Location"
        value={address}
        onChange={onAddressChange}
      />
      <UserSelect
        users={allUsers}
        selectedUser={eventUser}
        onSelectUser={onEventUserChange}
      />
      <TextInput
        label="Total Job Cost"
        placeholder=" "
        value={totalJobCost}
        onChange={onTotalJobCostChange}
      />
      <TextInput
        label="Material Cost"
        placeholder=" "
        value={materialCost}
        onChange={onMaterialCostChange}
      />
      <TextInput
        label="Labour Cost"
        placeholder=" "
        value={labourCost}
        onChange={onLabourCostChange}
      />
      <label
        style={{
          fontWeight: "bold",
          color: totalJobCost ? (jobProfit >= 0 ? "green" : "red") : "black",
        }}
      >
        Job Profit: £{jobProfit} (
        {totalJobCost
          ? ((jobProfit / (parseFloat(totalJobCost) || 1)) * 100).toFixed(2)
          : "0.00"}
        %)
      </label>
      <TextInput
        label="External Link"
        placeholder=" "
        value={externalLink}
        onChange={onExternalLinkChange}
      />
      <input type="file" onChange={(e) => setEventImage(e.target.files[0])} />

      <ModalButtons
        isEditMode={isEditMode}
        onSave={handleSave}
        onClear={() => {
          resetState();
        }}
        onClose={onClose}
        canSave={!isEngineer}
      />
    </Modal>
  );
};

export default CreateEventModal;
