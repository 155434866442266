// src/components/Charts/CustomRangeIncomeChart.js
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { fetchIncomeByDateRange } from '../../app/apiService';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const incomeTypes = ['Total Income', 'Total Profit'];

const CustomRangeIncomeChart = ({ startDate, endDate }) => {
  const [incomeData, setIncomeData] = useState({ totalIncome: 0, totalProfit: 0 });

  useEffect(() => {
    fetchIncomeByDateRange(startDate, endDate).then(data => {
      setIncomeData({ totalIncome: data.total_income, totalProfit: data.total_profit });
    }).catch(error => {
      console.error('Error fetching income data for custom range:', error);
    });
  }, [startDate, endDate]);

  const chartData = {
    labels: incomeTypes,
    datasets: [
      {
        label: 'Custom Range Income Status',
        data: [incomeData.totalIncome, incomeData.totalProfit],
        backgroundColor: ['rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)'],
        borderColor: ['rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    layout: {
        padding: {
          top: 50, // Add this line
        },
      },
    scales: {
      x: {
        type: 'category',
        ticks: {
          font: {
            size: 14, // Example size
            weight: 'bold', // Bolder font
          }
        }
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        ticks: {
            callback: function(value) {
                return '£' + value;
              },
          font: {
            size: 14,
            weight: 'bold',
          }
        }
      }
    },
    plugins: {
        tooltip: {
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += '£' + context.parsed.y.toFixed(2);
                }
                return label;
              }
            }
          },
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 14,
            weight: 'bold',
          }
        }
      },
      title: {
        display: false,
        text: 'Order Status by Income',
        font: {
          size: 18,
          weight: 'bold',
        }
      },
      datalabels: {
        color: '#444',
        anchor: 'end',
        align: 'top',
        font: {
            size: 15,
          weight: 'bold',
        },
        formatter: (value, context) => {
          // Only display the label if the value is greater than 0
          return value > 0 ? `£${value}` : '';
        },
      }
    },
    // ... other options
  };

  return (
    <div style={{ height: '370px', marginBottom: '30px' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Custom Range Income Status</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default CustomRangeIncomeChart;
